import { Paragraph, ParagraphsFactory } from 'components/ParagraphsFactory';

import type { FC } from 'react';
import WithLayout from '../WithLayout';

interface DataProps {
  content: any;
}

const Webinar: FC<DataProps> = ({ content }) => {
  const paragraphs = content.paragraphs?.map(({ entity }: { entity: Paragraph }) => ({
    type: entity.type,
    content: entity,
  }));

  return (
    <main role="main" data-node-id={content.id} about={content.url?.path}>
      {paragraphs && (
        <ParagraphsFactory paragraphs={paragraphs} langcode={content.langcode.value} />
      )}
    </main>
  );
};

export default WithLayout(Webinar);
